<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
  
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center"
          color="white"
          contain
        >
      
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="display-1"
            v-text="'Hyphen- Admin'"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
    
      <div />
      
      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <!-- <template v-slot:append>
      <base-item
        :item="{
          title: $t('upgrade'),
          icon: 'mdi-package-up',
          to: '/upgrade',
        }"
      />
    </template> -->
    <app-footer />
  </v-navigation-drawer>
</template>

<script>
import AppFooter from "./../core/Footer.vue";
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    components:{
      AppFooter
    },
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: []
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
      adminRoles(){
        this.items =  [{
          icon: 'mdi-home',
          title: 'Home',
          to: '/',
        },
         {
          icon: 'mdi-format-list-text',
          title: "Category",
          to:'/category'
        },
          {
          icon: 'mdi-account-hard-hat',
          title: "Construction",
          to:'/construction'
        },

        {
          icon: 'mdi-account',
          title: 'Admin',
          to: '/admin',
          
        },
         {
          icon: 'mdi-account',
          title: 'Moderator',
          to: '/moderator',
          
        },
           {
          icon: 'mdi-account',
          title: 'Expert',
          to: '/expert',
          
        },
        {
          icon: 'mdi-account',
          title: 'Customers',
          to: '/customers',
          
        },
       
          {
          icon: "mdi-chart-box-outline",
          title: 'Reports',
          to: '/reports',
          
        },
         {
          icon: 'mdi-cellphone-cog',
          title: 'Menu',
          to: '/menu-settings'
        },
           {
          icon: 'mdi-cellphone-cog',
          title: 'App Settings',
          to: '/app-settings'
        },
        {
          icon: 'mdi-cellphone-cog',
          title: 'Global Notification',
          to: '/global-notification'
        },
        
        ]
      },
      supervisorQnaRoles(){
          this.items =  [{
          icon: 'mdi-home',
          title: 'Home',
          to: '/',
          
        },
         {
          icon: 'mdi-account',
          title: 'Moderator',
          to: '/moderator',
        },
        {
          icon: 'mdi-account',
          title: 'Customers',
          to: '/customers',
          
        },
          {
          icon: "mdi-chart-box-outline",
          title: 'Reports',
          to: '/reports',
          
        }]
      },
      supervisorDesignRoles(){
          this.items =  [{
          icon: 'mdi-home',
          title: 'Home',
          to: '/',
          
        },
         {
          icon: 'mdi-account',
          title: 'Moderator',
          to: '/moderator',
        },
        {
          icon: 'mdi-account',
          title: 'Customers',
          to: '/customers',
          
        },
          {
          icon: "mdi-chart-box-outline",
          title: 'Reports',
          to: '/reports',
          
        }]
      },
      supervisorConstructionRoles(){
          this.items =  [{
          icon: 'mdi-home',
          title: 'Home',
          to: '/',
        },
       
         {
          icon: 'mdi-account',
          title: 'Moderator',
          to: '/moderator',
        },
        {
          icon: 'mdi-account',
          title: 'Customers',
          to: '/customers',
          
        },
        {
          icon: "mdi-chart-box-outline",
          title: 'Reports',
          to: '/reports',
          
        },
          {
          icon: 'mdi-account-hard-hat',
          title: "Construction",
          to:'/construction'
        },
        {
          icon: "mdi-account-voice",
          title: "On Site Consultation",
          to: "/on-site-consultation"
        },
        {
          icon: "mdi-video-plus",
          title: "Conference Call",
          to: "/video-conference"
        }
        
        
        ]
      },

      checkRoles(role){
        console.log("checking roles")
        if(role.toLowerCase()=='admin'){
          this.adminRoles();
        }else if(role.toLowerCase()=='supervisor_qna'){
          this.supervisorQnaRoles();
        }else if(role.toLowerCase()=='supervisor_design'){
          this.supervisorDesignRoles();
        }else if(role.toLowerCase()=='supervisor_construction'){
          this.supervisorConstructionRoles();
        }

      }
    },
    mounted(){
        this.$Auth
            .getOwnDetail()
            .then((data) => {
              
              this.$store.commit("setLoggedInUserDetails", data.data.data);
              
              this.checkRoles(data.data.data.profile);
            })
            .catch((error) => {
              console.log(error);
            });
    }
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
